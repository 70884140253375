let am = angular.module('ep-reports', []);

am.config(['$stateProvider', $stateProvider => {
  $stateProvider.
    state('reports', {
      url: '/reports',
      controller: 'ReportsCtrl',
      templateUrl: 'feature/reports/reports.html'
    })
    .state('onstar-rev-share-field-qualifier', {
      url: '/reports/onstar-rev-share-field-qualifier',
      controller: 'ReportDetailsCtrl',
      templateUrl: 'feature/reports/onstar-rev-share-field-qualifier.html'
    })
    .state('onstar-revenue-share-corporate-qualifier', {
      url: '/reports/onstar-revenue-share-corporate-qualifier',
      controller: 'ReportDetailsCtrl',
      templateUrl: 'feature/reports/onstar-revenue-share-corporate-qualifier.html'
    })
    .state('onstar-rev-share-corp-payout-vin', {
      url: '/reports/onstar-rev-share-corp-payout-vin',
      controller: 'ReportDetailsCtrl',
      templateUrl: 'feature/reports/onstar-rev-share-corp-payout-vin.html'
    })
    .state('onstar-rev-share-corp-payout-details', {
      url: '/reports/onstar-rev-share-corp-payout-details',
      controller: 'ReportDetailsCtrl',
      templateUrl: 'feature/reports/onstar-rev-share-corp-payout-details.html'
    })
    .state('guardian-transaction-corp-report', {
      url: '/reports/guardian-transaction-corp-report',
      controller: 'ReportDetailsCtrl',
      templateUrl: 'feature/reports/guardian-corporate-transaction-report.html'
    })
    .state('onstar-rev-share-myp-payout', {
      url: '/reports/onstar-rev-share-myp-payout',
      controller: 'ReportDetailsCtrl',
      templateUrl: 'feature/reports/onstar-rev-share-myp-payout.html'
    })
    .state('used-activation-report', {
      url: '/reports/used-activation-report',
      controller: 'ReportDetailsCtrl',
      templateUrl: 'feature/reports/used-activation-report.html'
    });
}]);

am.factory('reportsSvc', ['$http',
  ($http) => {
    let svc = {};
    svc.getReports = () => {
      const url = 'feature/data/spotlights.json';
      return $http.get(url);
    };
    return svc;
  }
]);

am.controller('ReportsCtrl', [
  '$scope', 'dialogSvc', 'L10nFilter', 'reportsSvc', '$rootScope',
  ($scope, dialogSvc, l10nFilter, reportsSvc, $rootScope) => {
    $scope.changeTab = function(tab) {
      $scope.view_tab = tab;
    };
    $scope.reportMenuText = "<<";
    $scope.reports = JSON.parse(sessionStorage.reportsAvailable);

    $scope.collapseMenu = function(){
        $('.row-offcanvas').toggleClass('active',500);
        if($scope.reportMenuText === ">>"){
          $scope.reportMenuText = "<<";
        }else{
          $scope.reportMenuText = ">>";
        }
    };

    $scope.model = {
      dropdownTitle: 'Select a Report',
      title: 'REPORTING DASHBOARD',
      icon: 'feature/reports/images/dashboard.png',
      haveEarnings: false,
      navOpen: true,
      showTypeMenu: true
    };

    if (!$scope.view_tab) {
      $scope.view_tab = 'tab1';
    }

    reportsSvc.getReports().then(res => {
      $scope.results = res.data;
    });

    $scope.saveProfile = function() {
      dialogSvc.showMessage(l10nFilter('profile-saved', null, true));
    };

    $scope.datesMissing = false;
    $scope.fieldsMissing = false;
    $scope.showRequiredFieldsMessage = false;
    $scope.search = function(resourceUrl){
      let filtersToApply = {};
      let filterArray = $scope.filters[resourceUrl];

      $scope.datesMissing = $scope.requiredDatesMissing(filterArray);
      $scope.fieldsMissing = $scope.requiredFiltersFieldsMissing(filterArray);

      if(!$scope.datesMissing && !$scope.fieldsMissing){
        for(var i = 0 ; i < filterArray.length ; i++){
            if(filterArray[i].value !== ''){
              filtersToApply[filterArray[i].name] = filterArray[i].value;
            }
          }
          $rootScope.$broadcast("filtersUpdated", {any: {filtersToApply, resourceUrl}});
          $scope.showRequiredFieldsMessage = false;
      }else{
        $scope.showRequiredFieldsMessage = true;
      }
    };

    $scope.requiredDatesMissing = function(filtersArray){
      console.log('inside requiredDatesMissing = ', filtersArray);
        var missingFields = false;
        for(var i = 0; i < filtersArray.length; i++){
            if(filtersArray[i].required === "true" && filtersArray[i].value === ''){
                missingFields = true;
            }
        }
        return missingFields;
    };

    $scope.requiredFiltersFieldsMissing = function(filtersArray){
      console.log('inside requiredFilterFieldsMissing = ', filtersArray);
        var fieldsRequired = ["region","zone","district","bac"];
        var missingFields = true;
        for(var i = 0; i < filtersArray.length; i++){
            for(var j = 0 ; j < fieldsRequired.length; j++){
                if(filtersArray[i].name === fieldsRequired[j] &&
                    filtersArray[i].value !== ''){
                        missingFields = false;
                        break;
                    }
            }
        }
        return missingFields;
    };

    $scope.clearFilters = function(report){
      let filterArray = $scope.filters[report];
      for(var i = 0 ; i < filterArray.length ; i++){
        if(filterArray[i].value !== ''){
          filterArray[i].value = '';
        }
      }
    };
  }
]);


am.controller('ReportDetailsCtrl', [
  '$rootScope','$scope',
  ($rootScope, $scope) => {
    $scope.zone = '';
    $scope.region = '';
    $scope.bac = '';
    $scope.mypMonth = '';
    $scope.mypYear = '';
    $scope.usedActivationMonth = '';
    $scope.usedActivationYear = '';
    $scope.district = '';
    $scope.revShareMonth = '';
    $scope.revShareYear = '';
    $scope.paymentStatus = '';
    $scope.transactionType = '';
    $scope.reportMenuText = "<<";
    $scope.toDate = '';
    $scope.fromDate = '';
    $scope.gmin = '';
    $scope.phoneNumber = '';
    $scope.collapseMenu = function(){
      $('.row-offcanvas').toggleClass('active',500);
      if($scope.reportMenuText === ">>"){
        $scope.reportMenuText = "<<";
      }else{
        $scope.reportMenuText = ">>";
      }
    };

    $scope.validateDates = function(reportName) {
      if (reportName === 'guardian-transaction-corp-report') {
        if(($scope.fromDate !== undefined  && $scope.fromDate !== '') && $scope.toDate !== undefined && ($scope.daysDiff($scope.fromDate,$scope.toDate) >= 0 && $scope.daysDiff($scope.fromDate,$scope.toDate) <= 365))  {
          return true;
        } else  {
          return false;
        }
      }
    };

    $scope.validateMonthYear = function(reportName) {
      if (reportName === 'onstar-rev-share-myp-payout') {
        if($scope.mypMonth !== undefined  && $scope.mypMonth !== '' && $scope.mypYear !== undefined  && $scope.mypYear !== '') {
          return true;
        } else  {
          return false;
        }
      }
      
      if (reportName === 'onstar-rev-share-used-activation') {
        if($scope.usedActivationMonth !== undefined  && $scope.usedActivationMonth !== '' && $scope.usedActivationYear !== undefined  && $scope.usedActivationYear !== '') {
          return true;
        } else  {
          return false;
        }
      }
    };

    $scope.validateBAC = function(reportName) {
      if (reportName === 'guardian-transaction-corp-report') {
        if ($scope.bac !== '') {
          return true;
        }
        else {
          return false;
        }
      }
    };

    $scope.validateGMIN = function(reportName) {
      if (reportName === 'guardian-transaction-corp-report') {
        if ($scope.gmin !== '') {
          return true;
        }
        else {
          return false;
        }
      }
    };
    
    $scope.validatePhone = function(reportName) {
      if (reportName === 'guardian-transaction-corp-report') {
        if ($scope.phoneNumber !== '') {
          return true;
        }
        else {
          return false;
        }
      }
    };

    $scope.validateInput = function(reportName){
      if (reportName === 'guardian-transaction-corp-report') {
        return true;
      }

     if($scope.zone === '' && $scope.region === '' && $scope.district === '' && $scope.bac === '' && $scope.paymentStatus === '' && $scope.transactionType === ''){
       return false;
     }
     if(reportName === 'onstar-rev-share-field-qualifier'){
       return true;
     }

     if(reportName === 'onstar-rev-share-myp-payout'){
      if($scope.bac !== ''){
        return true;
      }else{
        return false;
      }
    }

    if(reportName === 'onstar-rev-share-used-activation'){
      if($scope.bac !== ''){
        return true;
      }else{
        return false;
      }
    }

     if(reportName === 'onstar-revenue-share-corporate-qualifier'){
       if(($scope.deliveryFromDate !== undefined  && $scope.deliveryFromDate !== '') && $scope.deliveryToDate !== undefined  && ($scope.daysDiff($scope.deliveryFromDate,$scope.deliveryToDate) >= 0 && $scope.daysDiff($scope.deliveryFromDate,$scope.deliveryToDate) <= 90)){
         return true;
       }else{
         return false;
       }
     }else{
       if($scope.revShareMonth !== '' && $scope.revShareYear !== ''){
         return true;
       }else{
         return false;
       }
     }
    };

    $scope.daysDiff = function(first, second) {
      return Math.round((second-first)/(1000*60*60*24));
    };

    $scope.monthDiff = function(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth() + 1;
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    };

    $scope.searchByDate = function(resourceUrl) {
      $scope.filtersToApply = [];

      if (resourceUrl === 'guardian-transaction-corp-report') {
        if ($scope.fromDate && $scope.fromDate !== '' && $scope.toDate && $scope.toDate !== '') {
          $scope.filtersToApply.fromDate = new Date($scope.fromDate).getTime();
          $scope.filtersToApply.toDate = new Date($scope.toDate).getTime();
        }
      }
      $rootScope.$broadcast("filtersUpdated", {any: {filtersToApply : $scope.filtersToApply, resourceUrl}});
    };

    $scope.searchByMonthYear = function(resourceUrl) {
      $scope.filtersToApply = [];
      $scope.filtersToApply.mypMonth = $scope.mypMonth;
      $scope.filtersToApply.mypYear = $scope.mypYear;
      $scope.filtersToApply.usedActivationMonth = $scope.usedActivationMonth;
      $scope.filtersToApply.usedActivationYear = $scope.usedActivationYear;
    
      if($scope.validateMonthYear(resourceUrl)){
        $rootScope.$broadcast("filtersUpdated", {any: {filtersToApply : $scope.filtersToApply, resourceUrl}});
        $scope.clearBacFilters();
      }
    };

    $scope.searchByBAC = function(resourceUrl) {
      $scope.filtersToApply = [];

      if (resourceUrl === 'guardian-transaction-corp-report') {
        if ($scope.bac && $scope.bac !== '') {
          $scope.filtersToApply.bac = $scope.bac;
        }
      }
      $rootScope.$broadcast("filtersUpdated", {any: {filtersToApply : $scope.filtersToApply, resourceUrl}});
    };
    
    $scope.searchByGMIN = function(resourceUrl) {
      $scope.filtersToApply = [];

      if (resourceUrl === 'guardian-transaction-corp-report') {
        if ($scope.gmin && $scope.gmin !== '') {
          $scope.filtersToApply.gmin = $scope.gmin;
        }
      }
      $rootScope.$broadcast("filtersUpdated", {any: {filtersToApply : $scope.filtersToApply, resourceUrl}});
    };

    $scope.searchByPhone = function(resourceUrl) {
      $scope.filtersToApply = [];

      if (resourceUrl === 'guardian-transaction-corp-report') {
        if ($scope.phoneNumber && $scope.phoneNumber !== '') {
          $scope.filtersToApply.phoneNumber = $scope.phoneNumber;
        }
      }
      $rootScope.$broadcast("filtersUpdated", {any: {filtersToApply : $scope.filtersToApply, resourceUrl}});
    };

    $scope.search = function(resourceUrl){
     $scope.filtersToApply = [];
     $scope.filtersToApply.zone = $scope.zone;
     $scope.filtersToApply.region = $scope.region;
     $scope.filtersToApply.district = $scope.district;
     $scope.filtersToApply.bac = $scope.bac;
     $scope.filtersToApply.paymentStatus = $scope.paymentStatus;
     $scope.filtersToApply.revShareMonth = $scope.revShareMonth;
     $scope.filtersToApply.revShareYear = $scope.revShareYear;
     $scope.filtersToApply.mypMonth = $scope.mypMonth;
     $scope.filtersToApply.mypYear = $scope.mypYear;
     $scope.filtersToApply.usedActivationMonth = $scope.usedActivationMonth;
     $scope.filtersToApply.usedActivationYear = $scope.usedActivationYear;
     $scope.filtersToApply.transactionType = $scope.transactionType  === ' ' ? '':$scope.transactionType;

     if (resourceUrl === 'onstar-revenue-share-corporate-qualifier') {
        $scope.filtersToApply.deliveryFromDate = new Date($scope.deliveryFromDate).getTime();
        $scope.filtersToApply.deliveryToDate = new Date($scope.deliveryToDate).getTime();
     }

      if($scope.validateInput(resourceUrl)){
        $rootScope.$broadcast("filtersUpdated", {any: {filtersToApply : $scope.filtersToApply, resourceUrl}});
        $scope.clearMypFilters();
      }
    };

    $scope.clearMypFilters = function(){
      $scope.mypMonth = '';
      $scope.mypYear = '';
      $scope.usedActivationMonth = '';
      $scope.usedActivationYear = '';
    };
    $scope.clearBacFilters = function(){
      $scope.bac = '';
    };

    $scope.clearFilters = function(){
      $scope.zone = '';
      $scope.region = '';
      $scope.bac = '';
      $scope.district = '';
      $scope.paymentStatus = '';
      $scope.revShareMonth = '';
      $scope.revShareYear = '';
      $scope.mypMonth = '';
      $scope.mypYear = '';
      $scope.usedActivationMonth = '';
      $scope.usedActivationYear = '';
      $scope.transactionType = '';
      $scope.paymentStatus = '';
      $scope.deliveryFromDate = '';
      $scope.deliveryToDate = '';
      $scope.fromDate = '';
      $scope.toDate = '';
      $scope.gmin = '';
      $scope.phoneNumber = '';
    };

    $scope.months = ["january","february","march","april","may","june","july","august","september","october","november","december"];

    $scope.transactionTypes = {
      model:null,
      availableOptions:[
          {value:' ', name:"all"},
          {value:'new', name:"new"},
          {value:'pre-owned', name:"pre-owned"}
      ]
    };


    $scope.reportSuffix = sessionStorage.currentLang === "fr" ? "-fr" : "";

    $scope.reportName = window.location.href.split('/').pop();

  }
]);
